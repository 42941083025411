import React from 'react';
import classnames from 'classnames';

interface TabToggleProps {
  text: string;
  selected: boolean;
  onClick: any;
}

const TabToggle: React.FC<TabToggleProps> = props => {
  const classNames = classnames({
    'text-lg mr-4 cursor-pointer focus:outline-none': true,
    'font-bold text-primary border-b-2 border-primary': props.selected
  });
  return (
    <button className={classNames} onClick={props.onClick}>
      {props.text}
    </button>
  );
};

export default TabToggle;
