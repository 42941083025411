import React, { useState } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import groupBy from 'lodash.groupby';
import ContentContainer from '../../components/common/ContentContainer';
import Layout from '../../components/Layout';
import SEO from '../../components/Seo';
import ContentfulContent from './../../components/common/ContentfulContent';
import { extractContent } from './../../util';
import TabToggle from './../../components/common/TabToggle';
import classnames from 'classnames';
interface FaqSectionProps {
  faqs: any;
  hasShadow: boolean;
}

export const FaqSection = (props: FaqSectionProps) => {
  const [selectedFaq, setSelectedFaq] = useState('General Event Info');
  const groupedFaqs = groupBy(props.faqs, 'group');

  const faqFrameClasses = classnames({
    'bg-white rounded-2xl p-8 mb-6 content-body': true,
    shadow: props.hasShadow
  });

  const faqGroups = Object.keys(groupedFaqs);

  const visibleFaqGroups = faqGroups.filter(
    (group: string) => group === selectedFaq
  );
  return (
    <>
      <div className="py-2 px-6 relative">
        <h1 className="text-primary font-bold text-3xl text-center mb-10">
          Frequently Asked Questions for Events
        </h1>
        <div className="flex justify-center mb-6">
          <TabToggle
            text="General Event Info"
            selected={selectedFaq === 'General Event Info'}
            onClick={() => setSelectedFaq('General Event Info')}
          />
          <TabToggle
            text="Application Procedures"
            selected={selectedFaq === 'Application Procedures'}
            onClick={() => setSelectedFaq('Application Procedures')}
          />
          <TabToggle
            text="Summer Institutes"
            selected={selectedFaq === 'Summer Institutes'}
            onClick={() => setSelectedFaq('Summer Institutes')}
          />
        </div>
      </div>
      {visibleFaqGroups.map((group: string, i: number) => (
        <div className={faqFrameClasses} key={i}>
          <h3 className="text-primary font-bold text-3xl mb-4">{group}</h3>
          <>
            {groupedFaqs[group].map((faq: Faq, i: number) => (
              <div key={i}>
                <h4 className="text-gray-700 text-xl font-bold mb-6">
                  {faq.question}
                </h4>
                <div className="mb-6 text-gray-600">
                  <ContentfulContent content={faq.answer?.answer} />
                </div>
              </div>
            ))}
          </>
        </div>
      ))}
    </>
  );
};

type Faq = {
  question: string;
  answer: {
    answer: string;
  };
  group: string;
};

const EventFaqs: React.FC = props => {
  const data = useStaticQuery(graphql`
    {
      allContentfulEventFaq(filter: { node_locale: { eq: "en-US" } }) {
        edges {
          node {
            question
            answer {
              answer
            }
            group
          }
        }
      }
    }
  `);

  return (
    <Layout>
      <SEO title="Event Frequently Asked Questions" />
      <ContentContainer>
        <div className="w-full sm:w-3/4 flex flex-col m-auto">
          <FaqSection
            faqs={extractContent(data.allContentfulEventFaq)}
            hasShadow={true}
          />
        </div>
      </ContentContainer>
    </Layout>
  );
};

export default EventFaqs;
